import { useProduct } from "../../contexts/ProductCtx";
import { IoPencilSharp, IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Table, Button, Input } from "antd";
import { Notification } from "../../utils/Notification";
import { productServices } from "../../services/productServices";

const Product = () => {
  const { product, setProduct } = useProduct();
  const navigate = useNavigate();
  const { Search } = Input;

  const columns = [
    {
      title: "Д/д",
      dataIndex: "productId",
      key: "productId",
    },
    {
      title: "Нэр",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Код",
      dataIndex: "productCode",
      key: "productCode",
    },
    {
      title: "Үнэ",
      dataIndex: "productPrice",
      key: "productPrice",
    },
    // {
    //   title: "Төрөл",
    //   dataIndex: "productPageCtgType",
    //   key: "productPageCtgType",
    //   render: (text, record) => {
    //     if (record.productPageCtgType === 1) {
    //       return <p>Ажлын хувцас</p>;
    //     } else {
    //       return <p>Газ, Дугуй</p>;
    //     }
    //   },
    // },

    {
      title: "Засах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerEditBtn(record)}
          style={{ border: "none" }}
        >
          <IoPencilSharp />
        </Button>
      ),
    },
    {
      title: "Устгах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerDltBtn(record)}
          style={{ border: "none" }}
        >
          <IoCloseSharp />
        </Button>
      ),
    },
  ];
  function handlerAddBtn() {
    navigate("/product/create");
  }
  function handlerEditBtn(e) {
    navigate(`/product/${e.productId}`);
  }
  function handlerDltBtn(e) {
    productServices
      .getProductDlt(e.productId)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          Notification.openNotification("topRight", "success", result.msg);
          productServices
            .getAllProduct()
            .then((response) => response.json())
            .then((result) => {
              if (result.success === true) {
                setProduct(
                  result.data.map((row, i) => ({
                    productId: row.productId,
                    createdAt: row.createdAt,
                    createdBy: row.createdBy,
                    productCode: row.productCode,
                    productPrice: row.productPrice,
                    productCoverImg: row.productCoverImg,
                    productDesc: row.productDesc,
                    productLogos: row.productLogos,
                    productName: row.productName,
                    productSameImg: row.productSameImg,
                    productPageCtgType: row.productPageCtgType,
                    productSubCtgType: row.productSubCtgType,
                    productType: row.productType,
                    updatedAt: row.updatedAt,
                    key: i,
                  }))
                );
                navigate("/product");
              }
            });
        }
      });
  }
  function onSearch(e) {
    productServices
      .getSearchProduct(e)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setProduct(
            result.data.map((row, i) => ({
              productId: row.productId,
              createdAt: row.createdAt,
              createdBy: row.createdBy,
              productCode: row.productCode,
              productPrice: row.productPrice,
              productCoverImg: row.productCoverImg,
              productDesc: row.productDesc,
              productLogos: row.productLogos,
              productName: row.productName,
              productSameImg: row.productSameImg,
              productPageCtgType: row.productPageCtgType,
              productSubCtgType: row.productSubCtgType,
              productType: row.productType,
              updatedAt: row.updatedAt,
              key: i,
            }))
          );
        }
      });
  }

  return (
    <div className="w-full h-full">
      <div className="flex">
        <Button
          className="bg-[#1677ff] text-[#fff] mb-3"
          onClick={handlerAddBtn}
        >
          Бүтээгдэхүүн нэмэх
        </Button>
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          className="w-full ml-5"
        />
      </div>
      <Table dataSource={product} columns={columns} />
    </div>
  );
};
export default Product;
