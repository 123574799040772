import { useAbout } from "../../contexts/AboutCtx";
import { aboutServices } from "../../services/aboutServices";
import { IoPencilSharp, IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Table, Button, Input } from "antd";
import { Notification } from "../../utils/Notification";
import moment from "moment";

const About = () => {
  const { about, setAbout } = useAbout();
  const navigate = useNavigate();
  const { Search } = Input;
  const columns = [
    {
      title: "Д/д",
      dataIndex: "aboutId",
      key: "aboutId",
    },
    {
      title: "Огноо",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Гарчиг",
      dataIndex: "aboutTitle",
      key: "aboutTitle",
    },

    {
      title: "Засах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerEditBtn(record)}
          style={{ border: "none" }}
        >
          <IoPencilSharp />
        </Button>
      ),
    },
    {
      title: "Устгах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerDltBtn(record)}
          style={{ border: "none" }}
        >
          <IoCloseSharp />
        </Button>
      ),
    },
  ];
  function handlerAddBtn() {
    navigate("/about/create");
  }
  function handlerEditBtn(e) {
    navigate(`/about/${e.aboutId}`);
  }
  function handlerDltBtn(e) {
    aboutServices
      .getAboutDlt(e.aboutId)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          Notification.openNotification("topRight", "success", result.msg);
          aboutServices
            .getAllAbout()
            .then((response) => response.json())
            .then((result) => {
              if (result?.success === true) {
                setAbout(
                  result?.data.map((row, i) => ({
                    aboutId: row.aboutId,
                    aboutTitle: row.aboutTitle,
                    aboutBody: row.aboutBody,
                    createdBy: row.createdBy,
                    createdAt: moment(row.createdAt).format("L"),
                    key: i,
                  }))
                );
              }
            });
        }
      });
  }
  function onSearch(e) {
    aboutServices
      .getSearchAbout(e)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setAbout(
            result?.data.map((row, i) => ({
              aboutId: row.aboutId,
              aboutTitle: row.aboutTitle,
              aboutBody: row.aboutBody,
              createdBy: row.createdBy,
              createdAt: moment(row.createdAt).format("L"),
              key: i,
            }))
          );
        }
      });
  }

  return (
    <div className="w-full h-full">
      <div className="flex">
        <Button
          className="bg-[#1677ff] text-[#fff] mb-3"
          onClick={handlerAddBtn}
        >
          Бидний тухай нэмэх
        </Button>
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          className="w-full ml-5"
        />
      </div>
      <Table dataSource={about} columns={columns} />
    </div>
  );
};
export default About;
