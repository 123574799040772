import { useParams, useNavigate } from "react-router-dom";
import { aboutServices } from "../../services/aboutServices";
import { Notification } from "../../utils/Notification";
import { Editor } from "@tinymce/tinymce-react";
import { useAbout } from "../../contexts/AboutCtx";
import { useUser } from "../../contexts/LoginCtx";
import { useEffect, useState, useRef } from "react";
import { Button } from "antd";
import axios from "axios";
import moment from "moment";

const NewsDetails = () => {
  const { about, setAbout } = useAbout();
  const { user, setUser } = useUser();
  const [content, setContent] = useState();
  const [select, setSelect] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const { fileList, uploading } = useState();
  /*  Editor  */
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      setContent(editorRef.current.getContent());
    }
  };

  const onFinish = (values) => {
    values.preventDefault();
    if (id === "create") {
      aboutServices
        .getAboutCreate(values.target.aboutTitle.value, content, user.userId)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            aboutServices
              .getAllAbout()
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setAbout(
                    result?.data.map((row, i) => ({
                      aboutId: row.aboutId,
                      aboutTitle: row.aboutTitle,
                      aboutBody: row.aboutBody,
                      createdBy: row.createdBy,
                      createdAt: moment(row.createdAt).format("L"),
                      key: i,
                    }))
                  );
                  navigate("/about");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    } else {
      aboutServices
        .getAboutEdit(id, values.target.aboutTitle.value, content, user.userId)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            aboutServices
              .getAllAbout()
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setAbout(
                    result?.data.map((row, i) => ({
                      aboutId: row.aboutId,
                      aboutTitle: row.aboutTitle,
                      aboutBody: row.aboutBody,
                      createdBy: row.createdBy,
                      createdAt: moment(row.createdAt).format("L"),
                      key: i,
                    }))
                  );
                  navigate("/about");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    }
  };

  /* GetById user */
  useEffect(() => {
    aboutServices
      .getByIdAbout(id)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setSelect(result.data);
        }
      });
  }, [id]);

  return (
    <div>
      <form onSubmit={onFinish}>
        <div className="flex justify-center items-center">
          <label>Гарчиг </label>
          <input
            className="w-full my-2 p-1 px-3 ml-10 border border-1 rounded-md"
            name="aboutTitle"
            defaultValue={select?.aboutTitle}
          />
        </div>
        <Editor
          apiKey="0ukf61auxcpo055gt231aebyo79y9qho9dmce20ycd062s0k"
          cloudChannel="6-dev"
          initialValue={select?.aboutBody}
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              "a11ychecker",
              "advlist",
              "advcode",
              "advtable",
              "autolink",
              "checklist",
              "export",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "powerpaste",
              "fullscreen",
              "formatpainter",
              "insertdatetime",
              "media",
              "table",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | casechange blocks | bold italic backcolor | " +
              "alignleft aligncenter alignright alignjustify | " +
              "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
            selector: "textarea#drive",
            file_picker_types: "file image media",
            quickbars_insert_toolbar: "quickimage quicktable ",
            quickbars_image_toolbar:
              "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
            quickbars_selection_toolbar:
              "bold italic alignleft aligncenter alignright alignjustify ",
            automatic_uploads: true,
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement("input");
              if (meta.filetype === "file") {
                input.setAttribute("type", "file");
                input.onchange = function () {
                  var file = this.files[0];
                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "blobid" + new Date().getTime();
                    var blobCache = editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    let data = new FormData();
                    data.append("editorFile", blobInfo.blob());
                    axios
                      .post(
                        `${process.env.REACT_APP_ADMIN_URL}/editor/file`,
                        data
                      )
                      .then(function (res) {
                        res.data.file.map((file) => {
                          return cb(
                            `${process.env.REACT_APP_UPLOADS_URL}/${file}`
                          );
                        });
                      })
                      .catch(function (err) {
                        console.log(err);
                      });
                  };
                  reader.readAsDataURL(file);
                };
                input.click();
              }
              if (meta.filetype === "image") {
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");
                input.onchange = function () {
                  var file = this.files[0];
                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "blobid" + new Date().getTime();
                    var blobCache = editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    let data = new FormData();
                    data.append(
                      "editorImage",
                      blobInfo.blob(),
                      blobInfo.filename()
                    );
                    axios
                      .post(`${process.env.REACT_APP_ADMIN_URL}/editor`, data)
                      .then(function (res) {
                        res.data.images.map((image) => {
                          return cb(
                            ` ${process.env.REACT_APP_UPLOADS_URL}/${image}`
                          );
                        });
                      })
                      .catch(function (err) {
                        console.log(err);
                      });
                  };
                  reader.readAsDataURL(file);
                };
                input.click();
              }
              if (meta.filetype === "media") {
                input.setAttribute("type", "file");
                input.onchange = function () {
                  var file = this.files[0];
                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "blobid" + new Date().getTime();
                    var blobCache = editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    let data = new FormData();
                    data.append("editorVideo", blobInfo.blob());
                    axios
                      .post(
                        `${process.env.REACT_APP_ADMIN_URL}/editor/video`,
                        data
                      )
                      .then(function (res) {
                        res.data.video.map((file) => {
                          return cb(
                            `${process.env.REACT_APP_UPLOADS_URL}/${file}`
                          );
                        });
                      })
                      .catch(function (err) {
                        console.log(err);
                      });
                  };
                  reader.readAsDataURL(file);
                };
                input.click();
              }
            },
          }}
        />

        <Button
          onClick={log}
          className="bg-[#1677ff] text-[#fff] w-full"
          htmlType="submit"
        >
          Хадгалах
        </Button>
      </form>
    </div>
  );
};
export default NewsDetails;
