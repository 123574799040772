import { invoiceServices } from "../../services/invoiceServices";
import { useInvoice } from "../../contexts/InvoiceCtx";
import { IoCloseSharp, IoDocumentTextOutline } from "react-icons/io5";
import { Table, Button, Modal, Checkbox } from "antd";
import { Notification } from "../../utils/Notification";
import React, { useState, useEffect } from "react";
import moment from "moment";

const Invoice = () => {
  const { invoice, setInvoice } = useInvoice();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [select, setSelect] = useState();
  const [check, setCheck] = useState(false);
  const [totalSum, setTotalSum] = useState();

  // Modal
  function showModal(e) {
    setSelect(e);
    setIsModalOpen(true);
  }
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // Checked
  const onChange = (id, e) => {
    setCheck({
      id: id,
      check: e.target.checked,
    });
    invoiceServices
      .getEditInvoice(id, e.target.checked)
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          Notification.openNotification("topRight", "success", result.msg);
          invoiceServices
            .getAllInvoice(1, 10)
            .then((response) => response.json())
            .then((result) => {
              if (result.success === true) {
                setInvoice(result);
              }
            })
            .finally(() => {});
        }
      });
  };
  const columns = [
    {
      title: "invoiceChecked",
      dataIndex: "invoiceChecked",
      key: "invoiceChecked",
      render: (text, record) => (
        <>
          {record.invoiceChecked === 0 ? (
            <>
              <Checkbox onChange={(e) => onChange(record?.invoiceId, e)}>
                Төлөөгүй
              </Checkbox>
            </>
          ) : (
            <Checkbox
              checked={
                check?.id === record?.invoiceId || record.invoiceChecked
                  ? true
                  : false
              }
              onChange={(e) => onChange(record?.invoiceId, e)}
            >
              Төлсөн
            </Checkbox>
          )}
        </>
      ),
    },
    {
      title: "Нэр",
      dataIndex: "invoiceCustName",
      key: "invoiceCustName",
    },
    {
      title: "Регистр",
      dataIndex: "invoiceCustRegister",
      key: "invoiceCustRegister",
    },
    {
      title: "Огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <>{moment(record.createdAt).format("YYYY/MM/DD")}</>
      ),
    },
    {
      title: "Утас",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "Дүн",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button onClick={() => showModal(record)} style={{ border: "none" }}>
          <IoDocumentTextOutline />
        </Button>
      ),
    },
    {
      title: "Устгах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          // onClick={() => handlerDltBtn(record)}
          style={{ border: "none" }}
        >
          <IoCloseSharp />
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (select) {
      let totalSumFil = select.invoiceList.reduce((acc, item) => {
        return acc + item.productPiece * item.productPrice;
      }, 0);
      setTotalSum(totalSumFil);
    }
  }, [select]);

  return (
    <div>
      <Table dataSource={invoice?.data} columns={columns} />
      <Modal
        title="Нэхэмжлэлийн жагсаалт"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {select && (
          // <Table dataSource={select} columns={columnsList} className="mt-5" />
          <>
            <p>Нэхэмжлэлийн дугаар : {select?.invoiceNumber}</p>
            <table className="mt-5" id="customers">
              <tr>
                <th>№</th>
                <th>Барааны код</th>
                <th>Гүйлгээний утга</th>
                <th>Тоо хэмжээ</th>
                <th>Нэгжийн үнэ</th>
                <th>Нийт үнэ</th>
              </tr>
              {select &&
                select?.invoiceList.map((e, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{e.productCode}</td>
                      <td>{e.productName}</td>
                      <td>{e.productPiece}</td>
                      <td>{e.productPrice}</td>
                      <td>
                        {(e.productPrice * e.productPiece).toLocaleString(
                          "en-US",
                          { style: "decimal" }
                        )}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Дүн</td>
                <td>
                  {totalSum &&
                    (totalSum / 1.1).toLocaleString("en-US", {
                      style: "decimal",
                    })}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>НӨАТ</td>
                <td>
                  {totalSum &&
                    (((totalSum / 1.1) * 10) / 100).toLocaleString("en-US", {
                      style: "decimal",
                    })}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Нийт дүн</td>
                <td>
                  {totalSum &&
                    totalSum.toLocaleString("en-US", {
                      style: "decimal",
                    })}
                </td>
              </tr>
            </table>
          </>
        )}
        {/* <>
          {select === 0 ? (
            <Checkbox checked={true} onChange={onChange}>
              Төлсөн
            </Checkbox>
          ) : (
            <Checkbox
              checked={check === false ? false : true}
              onChange={onChange}
            >
              {check === false ? "Төлөөгүй" : "Төлсөн"}
            </Checkbox>
          )}
        </> */}
      </Modal>
    </div>
  );
};
export default Invoice;
