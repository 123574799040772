import { Editor } from "@tinymce/tinymce-react";
import { useProduct } from "../../contexts/ProductCtx";
import { useUser } from "../../contexts/LoginCtx";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { productServices } from "../../services/productServices";
import { categoryServices } from "../../services/categoryServices";
import { Notification } from "../../utils/Notification";
import { useCtg } from "../../contexts/CtgCtx";
import { Button, Select } from "antd";
import axios from "axios";

const productType = [
  {
    id: 1,
    name: "хуучин",
  },
  {
    id: 2,
    name: "шинэ",
  },
  {
    id: 3,
    name: "бэлэн",
  },
  {
    id: 4,
    name: "захиалга",
  },
  {
    id: 5,
    name: "шинэ/захиалга",
  },
  {
    id: 6,
    name: "хуучин/захиалга",
  },
  {
    id: 7,
    name: "шинэ/бэлэн",
  },
  {
    id: 8,
    name: "хуучин/бэлэн",
  },
];

const productLogos = [
  {
    value: "china.jpg",
    name: "хятад",
  },
  {
    value: "sutai.jpg",
    name: "Сутай ихэр цэцэг ",
  },
  {
    value: "mongolia.jpg",
    name: "монгол",
  },
  {
    value: "turkey.jpg",
    name: "турк",
  },
  {
    value: "korea1.jpg",
    name: "солонгос-1",
  },
  {
    value: "korea.jpg",
    name: "солонгос",
  },
];

const ProductDetails = () => {
  const { product, setProduct } = useProduct();
  const { user, setUser } = useUser();
  const [content, setContent] = useState();
  const [select, setSelect] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const { fileList, uploading } = useState();
  const { ctgs, setCtgs } = useCtg();
  const [pageCtg, setPageCtg] = useState();

  /*  Editor  */
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      setContent(editorRef.current.getContent());
    }
  };

  const onFinish = (values) => {
    values.preventDefault();
    if (id === "create") {
      productServices
        .getProductCreate(
          values.target.productCoverImg.files[0],
          values.target.productName.value,
          values.target.productCode.value,
          values.target.productPrice.value,
          values.target.productLogos.value,
          values.target.productSameImg.files,
          values.target.productPageCtgType.value,
          values.target.productSubCtgType.value,
          values.target.productType.value,
          content,
          user.userId
        )
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            productServices
              .getAllProduct()
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setProduct(
                    result.data.map((row, i) => ({
                      productId: row.productId,
                      createdAt: row.createdAt,
                      createdBy: row.createdBy,
                      productCode: row.productCode,
                      productPrice: row.productPrice,
                      productCoverImg: row.productCoverImg,
                      productDesc: row.productDesc,
                      productLogos: row.productLogos,
                      productName: row.productName,
                      productSameImg: row.productSameImg,
                      productPageCtgType: row.productPageCtgType,
                      productSubCtgType: row.productSubCtgType,
                      productType: row.productType,
                      updatedAt: row.updatedAt,
                      key: i,
                    }))
                  );
                  navigate("/product");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    } else {
      productServices
        .getProductEdit(
          id,
          values.target.productCoverImg.files[0],
          values.target.productName.value,
          values.target.productCode.value,
          values.target.productPrice.value,
          values.target.productLogos.value,
          values.target.productSameImg.files,
          values.target.productPageCtgType.value,
          values.target.productSubCtgType.value,
          values.target.productType.value,
          content,
          user.userId
        )
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            productServices
              .getAllProduct()
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setProduct(
                    result.data.map((row, i) => ({
                      productId: row.productId,
                      createdAt: row.createdAt,
                      createdBy: row.createdBy,
                      productCode: row.productCode,
                      productPrice: row.productPrice,
                      productCoverImg: row.productCoverImg,
                      productDesc: row.productDesc,
                      productLogos: row.productLogos,
                      productName: row.productName,
                      productSameImg: row.productSameImg,
                      productPageCtgType: row.productPageCtgType,
                      productSubCtgType: row.productSubCtgType,
                      productType: row.productType,
                      updatedAt: row.updatedAt,
                      key: i,
                    }))
                  );
                  navigate("/product");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    }
  };

  /* GetById user */
  useEffect(() => {
    categoryServices
      .getAllCtg("page")
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setPageCtg(result.data);
        }
      });

    productServices
      .getByIdProduct(id)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.success === true) {
          setSelect(result.data);
        }
      });
  }, [id]);
  console.log(select);
  return (
    <div>
      <form onSubmit={onFinish}>
        <div className="flex justify-center items-center">
          <label className="w-1/6">Нүүр зураг </label>
          <input
            accept="image/*"
            type="file"
            name="productCoverImg"
            className="w-5/6"
          />
        </div>
        <div className="flex justify-center items-center">
          <label className="w-1/6">Бүтээгдэхүүн нэр </label>
          <input
            className="w-5/6 my-2 p-1 px-3 border border-1 rounded-md"
            name="productName"
            defaultValue={select?.productName}
          />
        </div>
        <div className="flex justify-center items-center">
          <label className="w-1/6">Бүтээгдэхүүн код </label>
          <input
            className="w-5/6 my-2 p-1 px-3 border border-1 rounded-md"
            name="productCode"
            defaultValue={select?.productCode}
          />
        </div>
        <div className="flex justify-center items-center">
          <label className="w-1/6">Бүтээгдэхүүн үнэ </label>
          <input
            className="w-5/6 my-2 p-1 px-3 border border-1 rounded-md"
            name="productPrice"
            defaultValue={select?.productPrice}
          />
        </div>
        <div className="flex justify-center items-center">
          <label className="w-1/6">Үйлдвэрлэгч лого </label>
          {/* <input
            accept="image/*"
            multiple
            type="file"
            name="productLogos"
            className="w-5/6"
          /> */}
          <select
            name="productLogos"
            className="w-5/6 my-2 border rounded-md p-2"
          >
            {productLogos?.map((e, i) => (
              <option
                key={i}
                value={e.value}
                selected={
                  select?.productLogos[0] === e.value ? select.productLogos : ""
                }
              >
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center items-center">
          <label className="w-1/6">Бүтээгдэхүүн өнгө </label>
          <input
            accept="image/*"
            multiple
            type="file"
            name="productSameImg"
            className="w-5/6 my-2"
          />
        </div>
        <div className="flex justify-center items-center">
          <label className="w-1/6">Нүүр Ангилал </label>
          <select
            name="productPageCtgType"
            className="w-5/6 my-2 border rounded-md p-2"
          >
            {pageCtg?.map((e, i) => (
              <option
                key={i}
                value={e.id}
                selected={
                  select?.productPageCtgType === e.id
                    ? select.productPageCtgType
                    : ""
                }
              >
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center items-center">
          <label className="w-1/6"> Ангилал </label>
          <select
            name="productSubCtgType"
            className="w-5/6 my-2 border rounded-md p-2"
          >
            {ctgs?.map((e, i) => {
              return (
                <option
                  key={i}
                  value={`${e.id}`}
                  selected={
                    e.pageCategoryId === select?.productPageCtgType &&
                    e.id === select?.productSubCtgType
                      ? select.productSubCtgType
                      : ""
                  }
                >
                  {e.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex justify-center items-center">
          <label className="w-1/6">Бүтээгдэхүүн төрөл </label>
          <select
            name="productType"
            className="w-5/6 my-2 border rounded-md p-2"
            defaultValue={select?.productType}
          >
            {productType?.map((e, i) => {
              return (
                <option
                  key={i}
                  value={`${e.name}`}
                  selected={
                    e.name === select?.productType ? select?.productType : ""
                  }
                >
                  {e.name}
                </option>
              );
            })}
          </select>
        </div>
        <Editor
          apiKey="ykxkjlhd2fvg9a7438npciyxbqlml47jlo2izm9flcrbp4jv"
          cloudChannel="6-dev"
          initialValue={select?.productDesc}
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              "a11ychecker",
              "advlist",
              "advcode",
              "advtable",
              "autolink",
              "checklist",
              "export",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "powerpaste",
              "fullscreen",
              "formatpainter",
              "insertdatetime",
              "media",
              "table",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | casechange blocks | bold italic backcolor | " +
              "alignleft aligncenter alignright alignjustify | " +
              "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
            selector: "textarea#drive",
            file_picker_types: "file image media",
            quickbars_insert_toolbar: "quickimage quicktable ",
            quickbars_image_toolbar:
              "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
            quickbars_selection_toolbar:
              "bold italic alignleft aligncenter alignright alignjustify ",
            automatic_uploads: true,
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement("input");
              if (meta.filetype === "file") {
                input.setAttribute("type", "file");
                input.onchange = function () {
                  var file = this.files[0];
                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "blobid" + new Date().getTime();
                    var blobCache = editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    let data = new FormData();
                    data.append("editorFile", blobInfo.blob());
                    axios
                      .post(
                        `${process.env.REACT_APP_ADMIN_URL}/editor/file`,
                        data
                      )
                      .then(function (res) {
                        res.data.file.map((file) => {
                          return cb(
                            `${process.env.REACT_APP_UPLOADS_URL}/${file}`
                          );
                        });
                      })
                      .catch(function (err) {
                        console.log(err);
                      });
                  };
                  reader.readAsDataURL(file);
                };
                input.click();
              }
              if (meta.filetype === "image") {
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");
                input.onchange = function () {
                  var file = this.files[0];
                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "blobid" + new Date().getTime();
                    var blobCache = editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    let data = new FormData();
                    data.append(
                      "editorImage",
                      blobInfo.blob(),
                      blobInfo.filename()
                    );
                    axios
                      .post(`${process.env.REACT_APP_ADMIN_URL}/editor`, data)
                      .then(function (res) {
                        res.data.images.map((image) => {
                          return cb(
                            ` ${process.env.REACT_APP_UPLOADS_URL}/${image}`
                          );
                        });
                      })
                      .catch(function (err) {
                        console.log(err);
                      });
                  };
                  reader.readAsDataURL(file);
                };
                input.click();
              }
              if (meta.filetype === "media") {
                input.setAttribute("type", "file");
                input.onchange = function () {
                  var file = this.files[0];
                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "blobid" + new Date().getTime();
                    var blobCache = editorRef.current.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    let data = new FormData();
                    data.append("editorVideo", blobInfo.blob());
                    axios
                      .post(
                        `${process.env.REACT_APP_ADMIN_URL}/editor/video`,
                        data
                      )
                      .then(function (res) {
                        res.data.video.map((file) => {
                          return cb(
                            `${process.env.REACT_APP_UPLOADS_URL}/${file}`
                          );
                        });
                      })
                      .catch(function (err) {
                        console.log(err);
                      });
                  };
                  reader.readAsDataURL(file);
                };
                input.click();
              }
            },
          }}
        />

        <Button
          onClick={log}
          className="bg-[#1677ff] text-[#fff] w-full"
          htmlType="submit"
        >
          Хадгалах
        </Button>
      </form>
    </div>
  );
};
export default ProductDetails;
