import React, { createContext, useContext, useEffect, useState } from "react";
import { productServices } from "../services/productServices";
import moment from "moment";

const initialVal = {
  product: null,
  setProduct: () => {},
};

export const ProductContext = createContext(initialVal);

export function useProduct() {
  return useContext(ProductContext);
}

export const ProductProvider = (props) => {
  const [product, setProduct] = useState();
  useEffect(() => {
    productServices
      .getAllProduct()
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          setProduct(
            result.data.map((row, i) => ({
              productId: row.productId,
              createdAt: row.createdAt,
              createdBy: row.createdBy,
              productCode: row.productCode,
              productPrice: row.productPrice,
              productCoverImg: row.productCoverImg,
              productDesc: row.productDesc,
              productLogos: row.productLogos,
              productName: row.productName,
              productSameImg: row.productSameImg,
              productPageCtgType: row.productPageCtgType,
              productSubCtgType: row.productSubCtgType,
              productType: row.productType,
              updatedAt: row.updatedAt,
              key: i,
            }))
          );
        }
      })
      .finally(() => {});
  }, []);

  return (
    <ProductContext.Provider value={{ product, setProduct }}>
      {props.children}
    </ProductContext.Provider>
  );
};
export default ProductProvider;
