const getByIdCtg = async (id) => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/category?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getCtgEdit = async (id, name, pageCategoryId) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    id: id,
    name: name,
    pageCategoryId: pageCategoryId,
  });
  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/category?type=sub`,
    requestOptions
  );
};

const getAllCtg = async (type) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/category?type=${type}`,
    requestOptions
  );
};

const getSearchCategory = async (text) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/category/search?text=${text}`,
    requestOptions
  );
};

const getCtgCreate = async (name, pageCategoryId) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    name: name,
    pageCategoryId: pageCategoryId,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/category?type=sub`,
    requestOptions
  );
};

const getCtgDlt = async (id) => {
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/category?id=${id}`,
    requestOptions
  );
};

export const categoryServices = {
  getByIdCtg,
  getCtgEdit,
  getAllCtg,
  getSearchCategory,
  getCtgCreate,
  getCtgDlt,
};
