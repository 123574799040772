import { HiOutlineUser, HiOutlineLockClosed } from "react-icons/hi2";
import { loginServices } from "../../services/loginServices";
import { Notification } from "../../utils/Notification";
import { useUser } from "../../contexts/LoginCtx";
import { Button, Form, Input } from "antd";

function Login() {
  const { user, setUser } = useUser();

  const onFinish = (values) => {
    loginServices
      .getUserLogin(values.userName, values.password)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setUser({
            username: result.data.userName,
            token: result.token,
            userId: result.data.userId,
          });
          Notification.openNotification("topRight", "success", result.message);
        } else {
          Notification.openNotification("topRight", "error", result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="h-screen w-screen flex">
      <div
        className="bg-cover bg-right h-full w-1/4 md:w-1/3 lg:w-1/2"
        style={{ backgroundImage: "URL(./login.png)" }}
      ></div>
      <div className="w-3/4 md:w-2/3 lg:w-1/2 h-full flex flex-col justify-center px-5 md:px-20 lg:px-40">
        <>
          <div>
            <h2 className="uppercase text-center mb-10 text-2xl font-bold text-[#194a7e]">
              Сутайн ихэр цэцэг ХХК
            </h2>
          </div>
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item name="userName">
              <Input placeholder="Нэвтрэх нэр" prefix={<HiOutlineUser />} />
            </Form.Item>
            <Form.Item name="password">
              <Input.Password
                placeholder="Нууц үг"
                prefix={<HiOutlineLockClosed />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="w-full bg-[#194b82] text-[#fff]"
              >
                Нэвтрэх
              </Button>
            </Form.Item>
          </Form>
        </>
      </div>
    </div>
  );
}

export default Login;
