const getUserLogin = async (userName, passWord) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    userName: userName,
    userPassword: passWord,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_CLIENT_URL}/login`,
    requestOptions
  );
};

export const loginServices = {
  getUserLogin,
};
