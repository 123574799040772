const getByIdNews = async (id) => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/news?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getNewsEdit = async (
  newsId,
  newsTitle,
  newsBody,
  newsCoverImg,
  createdBy
) => {
  var formdata = new FormData();
  formdata.append("newsId", newsId);
  formdata.append("newsTitle", newsTitle);
  formdata.append("newsBody", newsBody);
  formdata.append("newsCoverImg", newsCoverImg);
  formdata.append("createdBy", createdBy);
  var requestOptions = {
    method: "PUT",
    body: formdata,
    redirect: "follow",
  };

  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/news`, requestOptions);
};

const getSearchNews = async (text) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/news/search?text=${text}`,
    requestOptions
  );
};

const getAllNews = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/news`, requestOptions);
};

const getNewsCreate = async (newsTitle, newsBody, newsCoverImg, createdBy) => {
  var formdata = new FormData();
  formdata.append("newsTitle", newsTitle);
  formdata.append("newsBody", newsBody);
  formdata.append("newsCoverImg", newsCoverImg);
  formdata.append("createdBy", createdBy);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/news`, requestOptions);
};

const getNewsDlt = async (id) => {
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/news?id=${id}`,
    requestOptions
  );
};

export const newsServices = {
  getByIdNews,
  getNewsEdit,
  getSearchNews,
  getAllNews,
  getNewsCreate,
  getNewsDlt,
};
