import { useParams, useNavigate } from "react-router-dom";
import { usersServices } from "../../services/usersServices";
import { Notification } from "../../utils/Notification";
import { useUsers } from "../../contexts/UsersCtx";
import { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import moment from "moment";

const UserDetails = () => {
  const { users, setUsers } = useUsers();
  const [select, setSelect] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  /* User create */
  const onFinish = (values) => {
    if (id === "create") {
      usersServices
        .getUserCreate(values.userName, values.userPassword)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            usersServices
              .getAllUsers()
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setUsers(
                    result?.data.map((row, i) => ({
                      userId: row.userId,
                      userName: row.userName,
                      userPassword: row.userPassword,
                      createdAt: moment(row.createdAt).format("L"),
                      updatedAt: row.updatedAt,
                      key: i,
                    }))
                  );
                  navigate("/");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    } else {
      usersServices
        .getUserEdit(id, values.userName, values.userPassword)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            usersServices
              .getAllUsers()
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setUsers(
                    result?.data.map((row, i) => ({
                      userId: row.userId,
                      userName: row.userName,
                      userPassword: row.userPassword,
                      createdAt: moment(row.createdAt).format("L"),
                      updatedAt: row.updatedAt,
                      key: i,
                    }))
                  );
                  navigate("/");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    }
  };

  /* GetById user */
  useEffect(() => {
    usersServices
      .getByIdUser(id)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setSelect(result.data);
        }
      });
  }, [id]);

  return (
    <div>
      <Form
        labelCol={{
          span: 4,
        }}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        fields={[
          {
            name: ["userName"],
            value: select?.userName,
          },
          {
            name: ["userPassword"],
            value: select?.userPassword,
          },
        ]}
      >
        <Form.Item label="Нэвтрэх нэр" name="userName">
          <Input />
        </Form.Item>
        <Form.Item label="Нууц үг" name="userPassword">
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button className="bg-[#1677ff] text-[#fff] w-full" htmlType="submit">
            Хадгалах
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default UserDetails;
