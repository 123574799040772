const getByIdBanner = async (id) => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/banner?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getSearchBanner = async (text) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/banner/search?text=${text}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getBannerEdit = async (bannerId, bannerName, bannerImg) => {
  var formdata = new FormData();
  formdata.append("bannerName", bannerName);
  formdata.append("bannerImg", bannerImg);
  formdata.append("bannerId", bannerId);

  var requestOptions = {
    method: "PUT",
    body: formdata,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/banner`,
    requestOptions
  );
};

const getAllBanner = async (type) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/banner`,
    requestOptions
  );
};

const getBannerCreate = async (bannerName, bannerImg) => {
  var formdata = new FormData();
  formdata.append("bannerName", bannerName);
  formdata.append("bannerImg", bannerImg);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/banner`,
    requestOptions
  );
};

const getPriceDlt = async (id) => {
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/banner?id=${id}`,
    requestOptions
  );
};

export const bannerServices = {
  getByIdBanner,
  getBannerEdit,
  getAllBanner,
  getSearchBanner,
  getBannerCreate,
  getPriceDlt,
};
