import { useBanner } from "../../contexts/BannerCtx";
import { IoPencilSharp, IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Table, Button, Input } from "antd";
import { Notification } from "../../utils/Notification";
import { bannerServices } from "../../services/bannerServices";

const Banner = () => {
  const { banner, setBanner } = useBanner();
  const navigate = useNavigate();
  const { Search } = Input;

  const columns = [
    {
      title: "Д/д",
      dataIndex: "bannerId",
      key: "bannerId",
    },

    {
      title: "Зураг",
      dataIndex: "bannerImg",
      key: "bannerImg",
      render: (text, record) => (
        <div>
          <img
            src={`${
              process.env.REACT_APP_UPLOADS_URL + "/" + record.bannerImg
            }`}
            alt={`${record.bannerImg}`}
            className="w-[100px] h-[70px] left-0 top-0"
          />
        </div>
      ),
    },
    {
      title: "Холбоос",
      dataIndex: "bannerName",
      key: "bannerName",
    },
    {
      title: "Засах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerEditBtn(record)}
          style={{ border: "none" }}
        >
          <IoPencilSharp />
        </Button>
      ),
    },
    {
      title: "Устгах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerDltBtn(record)}
          style={{ border: "none" }}
        >
          <IoCloseSharp />
        </Button>
      ),
    },
  ];
  function handlerAddBtn() {
    navigate("/banner/create");
  }
  function handlerEditBtn(e) {
    navigate(`/banner/${e.bannerId}`);
  }
  function handlerDltBtn(e) {
    bannerServices
      .getPriceDlt(e.bannerId)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          Notification.openNotification("topRight", "success", result.msg);
          bannerServices
            .getAllBanner()
            .then((response) => response.json())
            .then((result) => {
              if (result.success === true) {
                setBanner(
                  result?.data.map((row, i) => ({
                    bannerId: row.bannerId,
                    bannerName: row.bannerName,
                    bannerImg: row.bannerImg,
                    key: i,
                  }))
                );
              }
            });
        }
      });
  }
  function onSearch(e) {
    bannerServices
      .getSearchBanner(e)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setBanner(
            result?.data.map((row, i) => ({
              bannerId: row.bannerId,
              bannerName: row.bannerName,
              bannerImg: row.bannerImg,
              key: i,
            }))
          );
        }
      });
  }

  return (
    <div className="w-full h-full">
      <div className="flex">
        <Button
          className="bg-[#1677ff] text-[#fff] mb-3"
          onClick={handlerAddBtn}
        >
          Баннер нэмэх
        </Button>
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          className="w-full ml-5"
        />
      </div>
      <Table dataSource={banner} columns={columns} />
    </div>
  );
};
export default Banner;
