import "./App.css";
import { Login, Dashboard } from "./components/index";
import { useUser } from "./contexts/LoginCtx";

function App() {
  const { user, setUser } = useUser();
  return <div>{user ? <Dashboard /> : <Login />}</div>;
}

export default App;
