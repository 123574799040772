import { useUsers } from "../../contexts/UsersCtx";
import { usersServices } from "../../services/usersServices";
import { IoPencilSharp, IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Table, Button, Input } from "antd";
import { Notification } from "../../utils/Notification";
import moment from "moment";

const Users = () => {
  const { users, setUsers } = useUsers();
  const navigate = useNavigate();
  const { Search } = Input;

  const columns = [
    {
      title: "Огноо",
      dataIndex: "createdAt",
      key: "name",
    },
    {
      title: "Нэр",
      dataIndex: "userName",
      key: "age",
    },
    {
      title: "Засах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerEditBtn(record)}
          style={{ border: "none" }}
        >
          <IoPencilSharp />
        </Button>
      ),
    },
    {
      title: "Устгах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerDltBtn(record)}
          style={{ border: "none" }}
        >
          <IoCloseSharp />
        </Button>
      ),
    },
  ];
  function handlerAddBtn() {
    navigate("/users/create");
  }
  function handlerEditBtn(e) {
    navigate(`/users/${e.userId}`);
  }
  function handlerDltBtn(e) {
    usersServices
      .getUserDlt(e.userId)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          Notification.openNotification("topRight", "success", result.msg);
          usersServices
            .getAllUsers()
            .then((response) => response.json())
            .then((result) => {
              if (result.success === true) {
                setUsers(
                  result?.data.map((row, i) => ({
                    userId: row.userId,
                    userName: row.userName,
                    userPassword: row.userPassword,
                    createdAt: moment(row.createdAt).format("L"),
                    updatedAt: row.updatedAt,
                    key: i,
                  }))
                );
              }
            });
        }
      });
  }
  function onSearch(e) {
    usersServices
      .getSearchUsers(e)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setUsers(
            result?.data.map((row, i) => ({
              userId: row.userId,
              userName: row.userName,
              userPassword: row.userPassword,
              createdAt: moment(row.createdAt).format("L"),
              updatedAt: row.updatedAt,
              key: i,
            }))
          );
        }
      });
  }

  return (
    <div className="w-full h-full">
      <div className="flex">
        <Button
          className="bg-[#1677ff] text-[#fff] mb-3"
          onClick={handlerAddBtn}
        >
          Хэрэглэгч нэмэх
        </Button>
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          className="w-full ml-5"
        />
      </div>
      <Table dataSource={users} columns={columns} />
    </div>
  );
};
export default Users;
