const getByIdAbout = async (id) => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/about?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getAboutEdit = async (aboutId, aboutTitle, aboutBody, createdBy) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    aboutId: aboutId,
    aboutTitle: aboutTitle,
    aboutBody: aboutBody,
    createdBy: createdBy,
  });

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/about`,
    requestOptions
  );
};

const getAllAbout = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/about`,
    requestOptions
  );
};

const getSearchAbout = async (text) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/about/search?text=${text}`,
    requestOptions
  );
};

const getAboutCreate = async (aboutTitle, aboutBody, createdBy) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    aboutTitle: aboutTitle,
    aboutBody: aboutBody,
    createdBy: createdBy,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/about`,
    requestOptions
  );
};

const getAboutDlt = async (id) => {
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/about?id=${id}`,
    requestOptions
  );
};

export const aboutServices = {
  getByIdAbout,
  getAboutEdit,
  getAllAbout,
  getSearchAbout,
  getAboutCreate,
  getAboutDlt,
};
