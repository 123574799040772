const getByIdUser = async (id) => {
  return await fetch(`${process.env.REACT_APP_CLIENT_URL}/users/id?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getSearchUsers = async (text) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_CLIENT_URL}/users?text=${text}`,
    requestOptions
  );
};

const getUserEdit = async (userId, userName, userPassword) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    userId: userId,
    userName: userName,
    userPassword: userPassword,
  });
  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_CLIENT_URL}/users`,
    requestOptions
  );
};

const getAllUsers = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_CLIENT_URL}/users`,
    requestOptions
  );
};

const getUserCreate = async (userName, userPassword) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    userName: userName,
    userPassword: userPassword,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_CLIENT_URL}/users`,
    requestOptions
  );
};

const getUserDlt = async (userId) => {
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_CLIENT_URL}/users?id=${userId}`,
    requestOptions
  );
};

export const usersServices = {
  getByIdUser,
  getSearchUsers,
  getUserEdit,
  getAllUsers,
  getUserCreate,
  getUserDlt,
};
