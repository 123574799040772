import React, { useEffect } from "react";
import { Table } from "antd";
import { feedbackServices } from "../../services/feedbackServices";
import { useState } from "react";
import moment from "moment";

export default function Feedback() {
  const [data, setData] = useState();

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "createdAt",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "suggestion",
      dataIndex: "suggestion",
      key: "suggestion",
    },
  ];

  useEffect(() => {
    feedbackServices
      .getAllFeedback()
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setData(
            result?.data.map((row, i) => ({
              id: row.id,
              content: row.content,
              suggestion: row.suggestion,
              createdAt: moment(row.createdAt).format("L"),
              updatedAt: row.updatedAt,
              key: i,
            }))
          );
        }
      });
  }, []);
  return (
    <div>
      <Table dataSource={data} columns={columns} />
    </div>
  );
}
