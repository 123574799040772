import React, { createContext, useContext, useEffect, useState } from "react";
import { categoryServices } from "../services/categoryServices";

const initialVal = {
  ctgs: null,
  setCtgs: () => {},
};

export const CtgContext = createContext(initialVal);

export function useCtg() {
  return useContext(CtgContext);
}

export const CtgsProvider = (props) => {
  const [ctgs, setCtgs] = useState();
  useEffect(() => {
    categoryServices
      .getAllCtg("sub")
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setCtgs(
            result?.data.map((row, i) => ({
              id: row.id,
              name: row.name,
              pageCategoryId: row.pageCategoryId,
              key: i,
            }))
          );
        }
      })
      .finally(() => {});
  }, []);

  return (
    <CtgContext.Provider value={{ ctgs, setCtgs }}>
      {props.children}
    </CtgContext.Provider>
  );
};
export default CtgsProvider;
