import { useCtg } from "../../contexts/CtgCtx";
import { categoryServices } from "../../services/categoryServices";
import { IoPencilSharp, IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Table, Button, Input } from "antd";
import { Notification } from "../../utils/Notification";

const Category = () => {
  const { ctgs, setCtgs } = useCtg();
  const navigate = useNavigate();
  const { Search } = Input;

  const columns = [
    {
      title: "Д/д",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Нүүр категори",
    //   key: "key",
    //   dataIndex: "key",
    //   render: (text, record) => (
    //     <div>
    //       {record.pageCategoryId === 1
    //         ? "Ажлын хувцас"
    //         : record.pageCategoryId === 2
    //         ? "Газ, Дугуй"
    //         : record.pageCategoryId === 3
    //         ? "Танилцуулага, Мэдээ"
    //         : record.pageCategoryId === 4
    //         ? "Нэхэмжлэл, Үнийн санал"
    //         : "Холбоо барих"}
    //     </div>
    //   ),
    // },
    {
      title: "Засах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerEditBtn(record)}
          style={{ border: "none" }}
        >
          <IoPencilSharp />
        </Button>
      ),
    },
    {
      title: "Устгах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerDltBtn(record)}
          style={{ border: "none" }}
        >
          <IoCloseSharp />
        </Button>
      ),
    },
  ];
  function handlerAddBtn() {
    navigate("/category/create");
  }
  function handlerEditBtn(e) {
    navigate(`/category/${e.id}`);
  }
  function handlerDltBtn(e) {
    categoryServices
      .getCtgDlt(e.id)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          Notification.openNotification("topRight", "success", result.msg);
          categoryServices
            .getAllCtg("sub")
            .then((response) => response.json())
            .then((result) => {
              if (result.success === true) {
                setCtgs(
                  result?.data.map((row, i) => ({
                    id: row.id,
                    name: row.name,
                    pageCategoryId: row.pageCategoryId,
                    key: i,
                  }))
                );
              }
            });
        }
      });
  }

  function onSearch(e) {
    categoryServices
      .getSearchCategory(e)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setCtgs(
            result?.data.map((row, i) => ({
              id: row.id,
              name: row.name,
              pageCategoryId: row.pageCategoryId,
              key: i,
            }))
          );
        }
      });
  }

  return (
    <div className="w-full h-full">
      <div className="flex">
        <Button
          className="bg-[#1677ff] text-[#fff] mb-3"
          onClick={handlerAddBtn}
        >
          Ангилал нэмэх
        </Button>
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          className="w-full ml-5"
        />
      </div>
      <Table dataSource={ctgs} columns={columns} />
    </div>
  );
};
export default Category;
