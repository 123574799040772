import React, { createContext, useContext, useEffect, useState } from "react";
import { aboutServices } from "../services/aboutServices";
import moment from "moment";

const initialVal = {
  about: null,
  setAbout: () => {},
};

export const AboutContext = createContext(initialVal);

export function useAbout() {
  return useContext(AboutContext);
}

export const AboutProvider = (props) => {
  const [about, setAbout] = useState();
  useEffect(() => {
    aboutServices
      .getAllAbout()
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setAbout(
            result?.data.map((row, i) => ({
              aboutId: row.aboutId,
              aboutTitle: row.aboutTitle,
              aboutBody: row.aboutBody,
              createdBy: row.createdBy,
              createdAt: moment(row.createdAt).format("L"),
              key: i,
            }))
          );
        }
      })
      .finally(() => {});
  }, []);

  return (
    <AboutContext.Provider value={{ about, setAbout }}>
      {props.children}
    </AboutContext.Provider>
  );
};
export default AboutProvider;
