import { notification } from "antd";

const openNotification = (placement, type, msg) => {
  notification[type]({
    message: msg,
    duration: 1,
    placement,
  });
};

export const Notification = {
  openNotification,
};
