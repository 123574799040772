const getByIdProduct = async (id) => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/product?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getAllProduct = async () => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/product/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getSearchProduct = async (text) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/product/search?text=${text}`,
    requestOptions
  );
};

const getProductEdit = async (
  id,
  productCoverImg,
  productName,
  productCode,
  productPrice,
  productLogos,
  productSameImg,
  productPageCtgType,
  productSubCtgType,
  productType,
  productDesc,
  createdBy
) => {
  var formdata = new FormData();
  formdata.append("productId", id);
  formdata.append("productCoverImg", productCoverImg);
  formdata.append("productName", productName);
  formdata.append("productCode", productCode);
  formdata.append("productDesc", productDesc);
  formdata.append("productPrice", JSON.parse(productPrice));
  formdata.append("productLogos", productLogos);
  [...productSameImg].map((e) => {
    return formdata.append("productSameImg", e);
  });
  formdata.append("productPageCtgType", productPageCtgType);
  formdata.append("productSubCtgType", productSubCtgType);
  formdata.append("productType", productType);
  formdata.append("createdBy", createdBy);
  var requestOptions = {
    method: "PUT",
    body: formdata,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/product`,
    requestOptions
  );
};

const getProductCreate = async (
  productCoverImg,
  productName,
  productCode,
  productPrice,
  productLogos,
  productSameImg,
  productPageCtgType,
  productSubCtgType,
  productType,
  productDesc,
  createdBy
) => {
  var formdata = new FormData();
  formdata.append("productCoverImg", productCoverImg);
  formdata.append("productName", productName);
  formdata.append("productCode", productCode);
  formdata.append("productDesc", productDesc);
  formdata.append("productPrice", JSON.parse(productPrice));
  formdata.append("productLogos", productLogos);
  // [...productLogos].map((e) => {
  //   return formdata.append("productLogos", e);
  // });
  [...productSameImg].map((e) => {
    return formdata.append("productSameImg", e);
  });
  formdata.append("productPageCtgType", productPageCtgType);
  formdata.append("productSubCtgType", productSubCtgType);
  formdata.append("productType", productType);
  formdata.append("createdBy", createdBy);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/product`,
    requestOptions
  );
};

const getProductDlt = async (productId) => {
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/product?id=${productId}`,
    requestOptions
  );
};

export const productServices = {
  getByIdProduct,
  getSearchProduct,
  getProductEdit,
  getAllProduct,
  getProductCreate,
  getProductDlt,
};
