import { useNews } from "../../contexts/NewsCtx";
import { newsServices } from "../../services/newsServices";
import { IoPencilSharp, IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Table, Button, Input } from "antd";
import { Notification } from "../../utils/Notification";
import moment from "moment";

const News = () => {
  const { news, setNews } = useNews();
  const navigate = useNavigate();
  const { Search } = Input;
  const columns = [
    {
      title: "Д/д",
      dataIndex: "newsId",
      key: "newsId",
    },
    {
      title: "Огноо",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Гарчиг",
      dataIndex: "newsTitle",
      key: "newsTitle",
    },

    {
      title: "Засах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerEditBtn(record)}
          style={{ border: "none" }}
        >
          <IoPencilSharp />
        </Button>
      ),
    },
    {
      title: "Устгах",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <Button
          onClick={() => handlerDltBtn(record)}
          style={{ border: "none" }}
        >
          <IoCloseSharp />
        </Button>
      ),
    },
  ];
  function handlerAddBtn() {
    navigate("/news/create");
  }
  function handlerEditBtn(e) {
    navigate(`/news/${e.newsId}`);
  }
  function handlerDltBtn(e) {
    newsServices
      .getNewsDlt(e.newsId)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          Notification.openNotification("topRight", "success", result.msg);
          newsServices
            .getAllNews()
            .then((response) => response.json())
            .then((result) => {
              if (result?.success === true) {
                setNews(
                  result?.data.map((row, i) => ({
                    newsId: row.newsId,
                    newsTitle: row.newsTitle,
                    newsCoverImg: row.newsCoverImg,
                    newsBody: row.newsBody,
                    createdBy: row.createdBy,
                    createdAt: moment(row.createdAt).format("L"),
                    key: i,
                  }))
                );
              }
            });
        }
      });
  }
  function onSearch(e) {
    newsServices
      .getSearchNews(e)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setNews(
            result?.data.map((row, i) => ({
              newsId: row.newsId,
              newsTitle: row.newsTitle,
              newsCoverImg: row.newsCoverImg,
              newsBody: row.newsBody,
              createdBy: row.createdBy,
              createdAt: moment(row.createdAt).format("L"),
              key: i,
            }))
          );
        }
      });
  }

  return (
    <div className="w-full h-full">
      <div className="flex">
        <Button
          className="bg-[#1677ff] text-[#fff] mb-3"
          onClick={handlerAddBtn}
        >
          Мэдээ нэмэх
        </Button>
        <Search
          placeholder="input search text"
          onSearch={onSearch}
          className="w-full ml-5"
        />
      </div>
      <Table dataSource={news} columns={columns} />
    </div>
  );
};
export default News;
