import React, { createContext, useContext, useEffect, useState } from "react";
import { invoiceServices } from "../services/invoiceServices";

const initialVal = {
  invoice: null,
  setInvoice: () => {},
};

export const invoiceContext = createContext(initialVal);

export function useInvoice() {
  return useContext(invoiceContext);
}

export const InvoiceProvider = (props) => {
  const [invoice, setInvoice] = useState();
  useEffect(() => {
    invoiceServices
      .getAllInvoice(1, 10)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setInvoice(result);
        }
      })
      .finally(() => {});
  }, []);
  return (
    <invoiceContext.Provider value={{ invoice, setInvoice }}>
      {props.children}
    </invoiceContext.Provider>
  );
};
export default InvoiceProvider;
