import { useParams, useNavigate } from "react-router-dom";
import { categoryServices } from "../../services/categoryServices";
import { Notification } from "../../utils/Notification";
import { useCtg } from "../../contexts/CtgCtx";
import { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";

const CtgDetails = () => {
  const { ctgs, setCtgs } = useCtg();
  const [select, setSelect] = useState();
  const [pageCtg, setPageCtg] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  /* Category create */
  const onFinish = (values) => {
    if (id === "create") {
      categoryServices
        .getCtgCreate(values.name, values.pageCategoryId)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            categoryServices
              .getAllCtg("sub")
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setCtgs(
                    result?.data.map((row, i) => ({
                      id: row.id,
                      name: row.name,
                      pageCategoryId: row.pageCategoryId,
                      key: i,
                    }))
                  );
                  navigate("/category");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    } else {
      categoryServices
        .getCtgEdit(id, values.name, values.pageCategoryId)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            categoryServices
              .getAllCtg("sub")
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setCtgs(
                    result?.data.map((row, i) => ({
                      id: row.id,
                      name: row.name,
                      pageCategoryId: row.pageCategoryId,
                      key: i,
                    }))
                  );
                  navigate("/category");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    }
  };

  /* GetById user */
  useEffect(() => {
    categoryServices
      .getAllCtg("page")
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setPageCtg(result.data);
        }
      });
    categoryServices
      .getByIdCtg(id)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setSelect(result.data);
        }
      });
  }, [id]);

  return (
    <div>
      <Form
        labelCol={{
          span: 4,
        }}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        fields={[
          {
            name: ["name"],
            value: select?.name,
          },
          {
            name: ["pageCategoryId"],
            value: select?.pageCategoryId,
          },
        ]}
      >
        <Form.Item label="Категори нэр" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Категори төрөл" name="pageCategoryId">
          <Select
            options={pageCtg?.map((e) => {
              return {
                value: e.id,
                label: e.name,
              };
            })}
          />
        </Form.Item>
        <Form.Item>
          <Button className="bg-[#1677ff] text-[#fff] w-full" htmlType="submit">
            Хадгалах
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default CtgDetails;
