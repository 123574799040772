const getByIdPrice = async (id) => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/price?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getSearchPrice = async (text) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/price/search?text=${text}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getPriceEdit = async (priceId, priceBegin, priceEnd) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    priceId: priceId,
    priceBegin: priceBegin,
    priceEnd: priceEnd,
  });
  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/price`,
    requestOptions
  );
};

const getAllPrice = async (type) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/price`,
    requestOptions
  );
};

const getPriceCreate = async (priceBegin, priceEnd) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    priceBegin: priceBegin,
    priceEnd: priceEnd,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/price`,
    requestOptions
  );
};

const getPriceDlt = async (id) => {
  var requestOptions = {
    method: "DELETE",
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/price?id=${id}`,
    requestOptions
  );
};

export const priceServices = {
  getByIdPrice,
  getPriceEdit,
  getAllPrice,
  getSearchPrice,
  getPriceCreate,
  getPriceDlt,
};
