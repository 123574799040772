import { Route, Routes, Link, useLocation } from "react-router-dom";
import { Breadcrumb, Layout, Menu } from "antd";
import { AiOutlineTaobao } from "react-icons/ai";
import { useState } from "react";
import {
  Users,
  Product,
  ProductsDetails,
  Category,
  CtgDetails,
  Price,
  PriceDetails,
  Invoice,
  Feedback,
  Quotation,
  About,
  AboutDetails,
  News,
  NewsDetails,
  Banner,
  BannerDetails,
  UserDetails,
} from "../index";
const { Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem(<Link to="/">Админ</Link>, "1", <AiOutlineTaobao />),
  getItem("Бүтээгдэхүүн", "sub1", <AiOutlineTaobao />, [
    getItem(<Link to="/category">Ангилал</Link>, "3", <AiOutlineTaobao />),
    getItem(<Link to="/price">Үнэ</Link>, "4", <AiOutlineTaobao />),
    getItem(<Link to="/product">Бүтээгдэхүүн</Link>, "5", <AiOutlineTaobao />),
  ]),
  getItem("Үнийн ханш", "sub2", <AiOutlineTaobao />, [
    getItem(<Link to="/invoice">Нэхэмжлэл</Link>, "6", <AiOutlineTaobao />),
    getItem(<Link to="/feedback">Санал хүсэлт</Link>, "7", <AiOutlineTaobao />),
  ]),
  getItem("Байгууллага", "sub3", <AiOutlineTaobao />, [
    getItem(<Link to="/about">Бидний тухай</Link>, "8", <AiOutlineTaobao />),
    getItem(<Link to="/news">Мэдээ</Link>, "9", <AiOutlineTaobao />),
  ]),

  getItem(<Link to="/banner">Баннер</Link>, "10", <AiOutlineTaobao />),
];
function Pathname(pathname) {
  if (pathname === "/") {
    return "Админ";
  }
  if (pathname === "/banner") {
    return "Баннер";
  }
  if (pathname === "/product") {
    return "Бүтээгдэхүүн";
  }
  if (pathname === "/category") {
    return "Категори";
  }
  if (pathname === "/price") {
    return "Үнэ";
  }
  if (pathname === "/invoice") {
    return "Нэхэмжлэл";
  }
  if (pathname === "/quotation") {
    return "Үнийн санал";
  }
  if (pathname === "/info") {
    return "Танилцуулга";
  }
  if (pathname === "/news") {
    return "Мэдээ";
  }
}

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  function handleBtnExit() {
    window.localStorage.clear();
    window.location.reload();
  }

  return (
    <Layout className="min-h-screen ">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Menu
          theme="dark"
          className="mt-[10px] text-[#fff]"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout>
        <Content className="mx-[16px]">
          <div className="flex justify-between items-center">
            <Breadcrumb
              className="my-[16px]"
              items={[
                {
                  title: <Link to="/">Эхлэх</Link>,
                },

                {
                  title: Pathname(pathname),
                },
              ]}
            />
            <button onClick={handleBtnExit} className="hover:text-[red]">
              Гарах
            </button>
          </div>
          <div className="p-[24px] bg-[#fff] h-full">
            <Routes>
              <Route path="/" element={<Users />}></Route>
              <Route path="/users/:id" element={<UserDetails />} />
              <Route path="/product" element={<Product />}></Route>
              <Route path="/product/:id" element={<ProductsDetails />}></Route>
              <Route path="/category" element={<Category />}></Route>
              <Route path="/category/:id" element={<CtgDetails />} />
              <Route path="/price" element={<Price />}></Route>
              <Route path="/price/:id" element={<PriceDetails />}></Route>
              <Route path="/invoice" element={<Invoice />}></Route>
              <Route path="/feedback" element={<Feedback />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/about/:id" element={<AboutDetails />}></Route>
              <Route path="/news" element={<News />}></Route>
              <Route path="/news/:id" element={<NewsDetails />}></Route>
              <Route path="/banner" element={<Banner />}></Route>
              <Route path="/banner/:id" element={<BannerDetails />}></Route>
            </Routes>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Сутайн ихэр цэцэг ХХК
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
