import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  LoginProvider,
  UsersProvider,
  CtgsProvider,
  ProductProvider,
  PriceProvider,
  BannerProvider,
  NewsProvider,
  AboutProvider,
  InvoiceProvider,
} from "./contexts/index";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LoginProvider>
        <UsersProvider>
          <CtgsProvider>
            <ProductProvider>
              <PriceProvider>
                <BannerProvider>
                  <NewsProvider>
                    <AboutProvider>
                      <InvoiceProvider>
                        <App />
                      </InvoiceProvider>
                    </AboutProvider>
                  </NewsProvider>
                </BannerProvider>
              </PriceProvider>
            </ProductProvider>
          </CtgsProvider>
        </UsersProvider>
      </LoginProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
