import { useParams, useNavigate } from "react-router-dom";
import { bannerServices } from "../../services/bannerServices";
import { Notification } from "../../utils/Notification";
import { useBanner } from "../../contexts/BannerCtx";
import { useEffect, useState } from "react";
import { Button, Form, Input, Upload } from "antd";
import { AiOutlineUpload } from "react-icons/ai";

const BannerDetails = () => {
  const { banner, setBanner } = useBanner();
  const [select, setSelect] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const { fileList, uploading } = useState();
  console.log(banner);
  /* Category create */
  const onFinish = (values) => {
    if (id === "create") {
      bannerServices
        .getBannerCreate(
          values.bannerName,
          values.bannerImg.fileList[0].originFileObj
        )
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            bannerServices
              .getAllBanner()
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setBanner(
                    result?.data.map((row, i) => ({
                      bannerId: row.bannerId,
                      bannerName: row.bannerName,
                      bannerImg: row.bannerImg,
                      key: i,
                    }))
                  );
                  navigate("/banner");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    } else {
      bannerServices
        .getBannerEdit(
          id,
          values.bannerName,
          values.bannerImg.fileList[0].originFileObj
        )
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            bannerServices
              .getAllBanner()
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setBanner(
                    result?.data.map((row, i) => ({
                      bannerId: row.bannerId,
                      bannerName: row.bannerName,
                      bannerImg: row.bannerImg,
                      key: i,
                    }))
                  );
                  navigate("/banner");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    }
  };

  /* GetById user */
  useEffect(() => {
    bannerServices
      .getByIdBanner(id)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setSelect(result.data);
        }
      });
  }, [id]);

  return (
    <div>
      <Form
        labelCol={{
          span: 4,
        }}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        fields={[
          {
            name: ["bannerName"],
            value: select?.bannerName,
          },
          // {
          //   name: ["bannerImg"],
          //   value: select?.bannerImg,
          // },
        ]}
      >
        <Form.Item label="Зураг" name="bannerImg">
          <Upload beforeUpload={() => false} fileList={fileList}>
            <Button icon={<AiOutlineUpload />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item label="Холбоос" name="bannerName">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button className="bg-[#1677ff] text-[#fff] w-full" htmlType="submit">
            Хадгалах
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default BannerDetails;
