import React, { createContext, useContext, useEffect, useState } from "react";
import { usersServices } from "../services/usersServices";
import moment from "moment";

const initialVal = {
  users: null,
  setUsers: () => {},
};

export const UsersContext = createContext(initialVal);

export function useUsers() {
  return useContext(UsersContext);
}

export const UsersProvider = (props) => {
  const [users, setUsers] = useState();
  useEffect(() => {
    usersServices
      .getAllUsers()
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setUsers(
            result?.data.map((row, i) => ({
              userId: row.userId,
              userName: row.userName,
              userPassword: row.userPassword,
              createdAt: moment(row.createdAt).format("L"),
              updatedAt: row.updatedAt,
              key: i,
            }))
          );
        }
      })
      .finally(() => {});
  }, []);

  return (
    <UsersContext.Provider value={{ users, setUsers }}>
      {props.children}
    </UsersContext.Provider>
  );
};
export default UsersProvider;
