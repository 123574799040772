import { useParams, useNavigate } from "react-router-dom";
import { priceServices } from "../../services/priceServices";
import { Notification } from "../../utils/Notification";
import { usePrice } from "../../contexts/PriceCtx";
import { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import moment from "moment";

const PriceDetails = () => {
  const { price, setPrice } = usePrice();
  const [select, setSelect] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  /* Category create */
  const onFinish = (values) => {
    if (id === "create") {
      priceServices
        .getPriceCreate(values.priceBegin, values.priceEnd)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            priceServices
              .getAllPrice()
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setPrice(
                    result?.data.map((row, i) => ({
                      priceId: row.priceId,
                      priceBegin: row.priceBegin,
                      priceEnd: row.priceEnd,
                      key: i,
                    }))
                  );
                  navigate("/price");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    } else {
      priceServices
        .getPriceEdit(id, values.priceBegin, values.priceEnd)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("topRight", "success", result.msg);
            priceServices
              .getAllPrice()
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setPrice(
                    result?.data.map((row, i) => ({
                      priceId: row.priceId,
                      priceBegin: row.priceBegin,
                      priceEnd: row.priceEnd,
                      key: i,
                    }))
                  );
                  navigate("/price");
                }
              });
          } else {
            Notification.openNotification("topRight", "error", result.msg);
          }
        });
    }
  };

  /* GetById user */
  useEffect(() => {
    priceServices
      .getByIdPrice(id)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setSelect(result.data);
        }
      });
  }, [id]);

  return (
    <div>
      <Form
        labelCol={{
          span: 4,
        }}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        fields={[
          {
            name: ["priceBegin"],
            value: select?.priceBegin,
          },
          {
            name: ["priceEnd"],
            value: select?.priceEnd,
          },
        ]}
      >
        <Form.Item label="Эхлэх үнэ" name="priceBegin">
          <Input />
        </Form.Item>
        <Form.Item label="Дуусах үнэ" name="priceEnd">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button className="bg-[#1677ff] text-[#fff] w-full" htmlType="submit">
            Хадгалах
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default PriceDetails;
