import React, { createContext, useContext, useEffect, useState } from "react";

const initialVal = {
  user: null,
  setUser: () => {},
};

export const UserContext = createContext(initialVal);

export function useUser() {
  return useContext(UserContext);
}
export const LoginProvider = (props) => {
  const [user, setUser] = useState();
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user") || ""));
    }
  }, []);
  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};
export default LoginProvider;
