import React, { createContext, useContext, useEffect, useState } from "react";
import { priceServices } from "../services/priceServices";
import moment from "moment";

const initialVal = {
  price: null,
  setPrice: () => {},
};

export const PriceContext = createContext(initialVal);

export function usePrice() {
  return useContext(PriceContext);
}

export const PriceProvider = (props) => {
  const [price, setPrice] = useState();
  useEffect(() => {
    priceServices
      .getAllPrice()
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setPrice(
            result?.data.map((row, i) => ({
              priceId: row.priceId,
              priceBegin: row.priceBegin,
              priceEnd: row.priceEnd,
              key: i,
            }))
          );
        }
      })
      .finally(() => {});
  }, []);

  return (
    <PriceContext.Provider value={{ price, setPrice }}>
      {props.children}
    </PriceContext.Provider>
  );
};
export default PriceProvider;
