const getAllInvoice = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/invoice`,
    requestOptions
  );
};

const getEditInvoice = async (id, check) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify({
    id: id,
    check: check,
  });

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/invoice`,
    requestOptions
  );
};

export const invoiceServices = {
  getAllInvoice,
  getEditInvoice,
};
